import React from 'react';


export default class TreeSect extends React.Component {
    render() {
        return (
            <div className='treesect'>
                <div className='inv-wave'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00cba9" fill-opacity="1" d="M0,128L60,112C120,96,240,64,360,90.7C480,117,600,203,720,229.3C840,256,960,224,1080,218.7C1200,213,1320,235,1380,245.3L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
                </div>
                <div className="site-content">
                    <div className='treetext'>
                        <h1>
                            <span className='letter'>P</span><span className='remletters'>LUS</span> - Palakkad Libre software Users Society supports<br /> FOSS related activities in Palakkad.
                    </h1><br /><br />
                        <p className='treedesc'>
                            <span className='treequote1'>&ldquo;</span>Free software is a matter of liberty, not price. You should think of 'free' as in 'free speech' and not as in 'free ice-cream'<span className='treequote2'>&rdquo;</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

}
